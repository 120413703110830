.job-opening-card {
    width: 100%;
    margin: 10px 0;
    padding: 12px 24px;
    background: rgba(255,255,255,.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: var(--border-radius);
    -webkit-border-radius: var(--border-radius);
    -moz-border-radius: var(--border-radius);
    -ms-border-radius: var(--border-radius);
    -o-border-radius: var(--border-radius);
}

.job-opening-card .header {
    display: flex;
    align-items: center;
    gap: 20px;
}

@media only screen and (max-width: 900px) {
    .job-opening-card .header {
        display: flex;
        flex-direction: column;
    }
}

.job-opening-card .header .logo {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.job-opening-card .header .details {
    display: flex;
    justify-content: end;
    flex-grow: 1;
    gap: 20px;
}

.job-opening-card .content {
    margin: 20px;
}

.job-opening-card .content > * {
    margin-top: 20px;
}

@media only screen and (max-width: 900px) {
    .job-opening-card .content {
        text-align: center;
    }
    .job-opening-card .content .title {
        font-size: 1rem;
    }
}

.job-opening-card .publisher {
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: 900px) {
    .job-opening-card .publisher {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
}

.job-opening-card .links {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.job-opening-card .links a {
    color: var(--color-light);
    margin: 10px 0;
    text-decoration: none;
}