* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
}

:root {
  --color-dark: rgba(12,12,12,1);
  --rgb-dark: 12,12,12;
  --color-light: rgba(239,239,239,1);
  --rgb-dark: 239,239,239;

  --border-radius: 10px;
}

html,
body {
  /* background: #ffe259;
  background: -webkit-linear-gradient(to right, #ffa751, #ffe259);
  background: linear-gradient(to right, #ffa751, #ffe259); */
  background: #bdc3c7;
  background: -webkit-linear-gradient(to right, #5e86ad, #6f8899);
  background: linear-gradient(to right, #5e86ad, #6f8899);
}

.view {
  padding: 10px 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1300px) {
  .view {
    padding: 10px 150px;
  }
}

@media only screen and (max-width: 900px) {
  .view {
    padding: 10px 10px;
  }
}

.view-title {
  color: white;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form .input {
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
}

form label {
  font-weight: 600;
  font-size: 1.2rem;
  color: white;
}

form input,
form select {
  padding: 10px 20px;
  font-size: 19px;
  background: rgba(255,255,255,.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: none;
  border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -ms-border-radius: var(--border-radius);
  -o-border-radius: var(--border-radius);
}

form option {
  background: rgba(255,255,255,.3);
}

form button {
  padding: 10px 20px;
  background: var(--color-light);
  color: var(--color-dark);
  background: rgba(255,255,255,.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: none;
  font-weight: 600;
  font-size: 1.1rem;
  color: white;
  border-radius: var(--border-radius);
  -webkit-border-radius: var(--border-radius);
  -moz-border-radius: var(--border-radius);
  -ms-border-radius: var(--border-radius);
  -o-border-radius: var(--border-radius);
  cursor: pointer;
}

.loading-spinner {
  height: 100px;
  width: 100px;
}